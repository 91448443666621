const routes = [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "BeMagnified",
    },
  },
  {
    path: "/rebrand",
    meta: {
      title: "BeMagnified",
    },
    component: () => import("../views/Landing.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "About | BeMagnified",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/services",
    name: "Services",
    meta: {
      title: "Services | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/development",
    name: "Development",
    meta: {
      title: "Service | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/design",
    name: "Design",
    meta: {
      title: "Service | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/marketing",
    name: "Marketing",
    meta: {
      title: "Marketing | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/photovideo",
    name: "PhotoVideo",
    meta: {
      title: "Photo/Video | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: {
      title: "Portfolio | BeMagnified",
    },
    component: () => import("../views/portfolio/Portfolio.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Contact | BeMagnified",
    },
    component: () => import("../views/Contact.vue"),
  },

  // portfolio pages
  {
    path: '/portfolio/:projectName',
    component: () => import('../views/portfolio/project/Index.vue'),
    props: true,
    meta: {
      title: "Portfolio | BeMagnified",
      sitemap: { ignoreRoute: true }
    },
  },
  
  {
    path: "*",
    component: () => import('../views/404.vue'),
    meta: {
      title: "404 | BeMagnified",
    },
  },
];

module.exports = { routes };